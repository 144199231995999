import {Link} from "react-router-dom";


export default function GlobalFooter() {
    function Click() {
        window.scrollTo(0, 0)
    }

    return (
        <div className="">
            <footer className="footer p-10 md:justify-items-center bg-secondary text-secondary-content">
                    <div>
                        <span className="footer-title">Dienstleistungen</span>
                        <a href="/#Rezeptbestellungen" className="link link-hover">Rezeptbestellungen</a>
                        <Link to="/sprechstunden" className="link link-hover" onClick={Click}>Sprechstunden</Link>
                        <Link to="/notfall" className="link link-hover" onClick={Click}>Bei Notfällen</Link>
                    </div>
                    <div>
                        <span className="footer-title">Praxis</span>
                        <Link to="/ueberuns" className="link link-hover" onClick={Click}>Über uns</Link>
                        <a href="/#Kontakt" className="link link-hover">Kontakt</a>
                        <Link to="/Bewerbung" className="link link-hover" onClick={Click}>Bewerbung</Link>
                    </div>
                    <div>
                        <span className="footer-title" onClick={Click}>Rechtliches</span>
                        <Link to="/impressum" className="link link-hover" onClick={Click}>Impressum</Link>
                        <Link to="/datenschutz" className="link link-hover" onClick={Click}>Datenschutzerklärung</Link>
                    </div>
            </footer>
        </div>
    )
}