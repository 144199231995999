export default function DSVGO() {
    return(
        <div className="container flex-1 overflow-y-auto mx-auto px-8 py-8">
            <div>
                <div>
                    <div className="text-2xl font-bold"><h1>Datenschutzerklärung</h1></div>
                    <p><strong>Allgemeiner Hinweis und Pflichtinformationen</strong></p>
                    <p><strong>Benennung der verantwortlichen Stelle</strong></p>
                    <div>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</div>
                    <div className="py-1.5"></div>
                    <div>
                        <span>Praxis Dr. med. Thomas Bonin</span><br></br>
                        <span>Dr. med. Thomas Bonin</span><br></br>
                        <span>Büchelbach 18</span><br></br>
                        <span>63599 Biebergemünd - Bieber</span>
                    </div>
                    <div className="py-1.5"></div>
                    <p>Die verantwortliche Stelle entscheidet allein oder gemeinsam mit anderen über die Zwecke und
                        Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, Kontaktdaten o. Ä.).</p>

                    <p><strong>Widerruf Ihrer Einwilligung zur Datenverarbeitung</strong></p>
                    <p>Nur mit Ihrer ausdrücklichen Einwilligung sind einige Vorgänge der Datenverarbeitung möglich. Ein
                        Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt
                        eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten
                        Datenverarbeitung bleibt vom Widerruf unberührt.</p>
                    <p><strong>Recht auf Beschwerde bei der zuständigen Aufsichtsbehörde</strong></p>
                    <p>Als Betroffener steht Ihnen im Falle eines datenschutzrechtlichen Verstoßes ein Beschwerderecht
                        bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde bezüglich
                        datenschutzrechtlicher Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem sich
                        der Sitz unseres Unternehmens befindet. Der folgende Link stellt eine Liste der
                        Datenschutzbeauftragten sowie deren Kontaktdaten bereit: <a className="link link-hover
                        text-sky-700 after:content-['_↗']"
                            href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">
                            https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.
                    </p>

                    <p><strong>Recht auf Datenübertragbarkeit</strong></p>
                    <p>Ihnen steht das Recht zu, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines
                        Vertrags automatisiert verarbeiten, an sich oder an Dritte aushändigen zu lassen. Die
                        Bereitstellung erfolgt in einem maschinenlesbaren Format. Sofern Sie die direkte Übertragung der
                        Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar
                        ist.</p>

                    <p><strong>Recht auf Auskunft, Berichtigung, Sperrung, Löschung</strong></p>
                    <p>Sie haben jederzeit im Rahmen der geltenden gesetzlichen Bestimmungen das Recht auf
                        unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, Herkunft der Daten,
                        deren Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung,
                        Sperrung oder Löschung dieser Daten. Diesbezüglich und auch zu weiteren Fragen zum Thema
                        personenbezogene Daten können Sie sich jederzeit über die im Impressum aufgeführten
                        Kontaktmöglichkeiten an uns wenden.</p>

                    <p><strong>SSL- bzw. TLS-Verschlüsselung</strong></p>
                    <p>Aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, die Sie an uns als
                        Seitenbetreiber senden, nutzt unsere Website eine SSL-bzw. TLS-Verschlüsselung. Damit sind
                        Daten, die Sie über diese Website übermitteln, für Dritte nicht mitlesbar. Sie erkennen eine
                        verschlüsselte Verbindung an der „https://“ Adresszeile Ihres Browsers und am Schloss-Symbol in
                        der Browserzeile.</p>
                </div>
                <div className="">
                    <p><strong>Server-Log-Dateien</strong></p>
                    <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen,
                        die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                    <ul className="list-disc list-inside">
                        <li>Besuchte Seite auf unserer Domain</li>
                        <li>Datum und Uhrzeit der Serveranfrage</li>
                        <li>Browsertyp und Browserversion</li>
                        <li>Verwendetes Betriebssystem</li>
                        <li>Referrer URL</li>
                        <li>Hostname des zugreifenden Rechners</li>
                        <li>IP-Adresse</li>
                    </ul>
                    <p>Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der
                        Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur
                        Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</p>
                </div>
            </div>
        </div>
    )
}