import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import Team from "./pages/Team";
import Leistungen from "./pages/Leistungen";
import Bewerbung from "./pages/Bewerbung";
import DSVGO from "./pages/DSVGO";
import Notfall from "./pages/Notfall";
import Impressum from "./pages/Impressum";
import AboutUs from "./pages/AboutUs";
import Sprechstunden from "./pages/Sprechstunden";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



const Error404 = () =>
    <div className="px-16 py-32">
    <div className="px-8 py-8 card bg-red-500 hover:text-white shadow-2xl hover:shadow-md">
        <div className="card-body">
            <h1 className="card-title">404 – Seite nicht gefunden</h1>
            Haben Sie sich in der Addresse vertippt?
        </div>
    </div>
    </div>;

function fourtwenty() {
    return (
        <div className="px-16 py-32">
            <div className="px-8 py-8 card bg-green-500 hover:text-white shadow-2xl hover:shadow-md">
                <div className="card-body">
                    <h1 className="card-title">420 Blaze It VapeNation</h1>
                    Sehr lustig. Aber bei uns bekommen Sie kein Grass ohne medizinische Gründe.
                </div>
            </div>
        </div>
    );
}

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={ <App/> } >
                      <Route path="/" element={ <HomePage/> } />
                      <Route path="team" element={ <Team/> } />
                      <Route path="leistungen" element={ <Leistungen/> } />
                      <Route path="bewerbung" element={ <Bewerbung/> } />
                      <Route path="ueberuns" element={ <AboutUs/> }/>
                      <Route path="sprechstunden" element={ <Sprechstunden/> } />
                      <Route path="notfall" element={ <Notfall/>} />
                      <Route path="impressum" element={ <Impressum/>} />
                      <Route path="datenschutz" element={<DSVGO/>} />
                      <Route path="420" element={ fourtwenty() } />
                      <Route path="*" element={<Error404/>}/>
              </Route>
          </Routes>
      </BrowserRouter>
  </React.StrictMode>
);
/*root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Switch>
                    <Route path="/" element={ <HomePage/> } />
                    <Route path="team" element={ <Team/> } />
                    <Route path="leistungen" element={ <Leistungen/> } />
                    <Route path="bewerbung" element={ <Bewerbung/> } />
                    <Route path="420" element={ fourtwenty() } />
                    <Route path="*" element={<Error404/>}/>
            </Switch>
        </BrowserRouter>
    </React.StrictMode>
);*/


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
