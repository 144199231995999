import React from "react";

function TeamCards() {
    return(
        <div className="flex flex-col">

            <div className="">
                <img className="mx-auto rounded-box shadow-xl" src="/IMG_4249.jpg" alt="Thomas Bonin"/>
            </div>

            <div className="py-8">
                <p className="text-xl xl:text-3xl font-bold">Ihr Praxisteam</p>
            </div>

            <div className="grid max-sm:grid-cols-1 grid-cols-2 xl:grid-cols-3 gap-8">
                <div className="card glass shadow-2xl hover:shadow-2xl w-auto">
                    <figure className=""><img src="/drthomasbonin_720.jpg" alt="Thomas Bonin"/></figure>
                    <div className="card-body">
                        <h2 className="card-title">Dr. med. Thomas Bonin</h2>
                    </div>
                </div>

                <div className="card glass shadow-2xl hover:shadow-2xl w-auto">
                    <figure className=""><img src="/mitarbeiter1_720.jpg" alt="mitarbeiter1"/></figure>
                    <div className="card-body">
                        <h2 className="card-title">Frau Carina Schön</h2>
                    </div>
                </div>

                <div className="card glass shadow-2xl hover:shadow-2xl w-auto">
                    <figure className=""><img src="/mitarbeiter2_720.jpg" alt="mitarbeiter2"/></figure>
                    <div className="card-body">
                        <h2 className="card-title">Frau Elke Schneider</h2>
                    </div>
                </div>

                <div className="card glass shadow-2xl hover:shadow-2xl w-auto">
                    <figure className=""><img src="/Silvana_Bosnjakovic.jpg" alt="mitarbeiter4"/></figure>
                    <div className="card-body">
                        <h2 className="card-title">Frau Silvana Bosnjakovic</h2>
                    </div>
                </div>

                <div className="card glass shadow-2xl hover:shadow-2xl w-auto">
                    <figure className=""><img src="/mitarbeiter3_720.jpg" alt="mitarbeiter3"/></figure>
                    <div className="card-body">
                        <h2 className="card-title">Frau Sabrina Lückel</h2>
                    </div>
                </div>

                <div className="card glass shadow-2xl hover:shadow-2xl w-auto">
                    <figure className=""><img src="/maritess720.jpg" alt="Maritess"/></figure>
                    <div className="card-body">
                        <h2 className="card-title">Frau Maritess Huth-Bonin</h2>
                    </div>
                </div>

            </div>
            <div className="py-8"></div>
        </div>
    );
}
export default function Team() {
    return(
        <div className="container mx-auto flex px-8 py-8">
            <div>
                <TeamCards/>
            </div>
        </div>
    );
}