import React from "react";

export default function Leistungen() {
    return(
        <div className="container mx-auto px-8 py-8">

            <div>
                <p className="text-xl xl:text-3xl font-bold">Unser Leistungsspektrum</p>
                <div className="py-2"></div>
                <ul className=" leading-loose list-disc list-inside">
                    <li className="font-bold leading-[1.5rem]">Hausärztliche Versorgung
                        <div className=" font-normal"></div>
                    </li>
                    <li className="leading-loose font-bold">Heim- und Hausbesuche bei Immobilität
                        <div className=" font-normal"></div>
                    </li>
                    <li className="leading-loose font-bold">Gesundheitsuntersuchungen
                        <span className=" font-normal"> (in den von den Krankenversicherungen vorgegebenen Zeitabständen)</span>
                    </li>
                    <li className="leading-loose font-bold">Hautkrebs-Screening
                        <span className=" font-normal"> (alle 2 Jahre)</span>
                    </li>
                    <li className="leading-loose font-bold">Krebsvorsorge
                        <span className=" font-normal"> (jährlich, Frauen ab 25 und Männer ab 45 Jahren)</span>
                    </li>
                    <li className="leading-loose font-bold">Ultraschall-Untersuchungen
                        <span className=" font-normal"> (Weichteile, Schilddrüse, Brustkorb, Bauch, uro-gynäkologisch, Schlagadern, usw.)</span>
                    </li>
                    <li className="leading-loose font-bold">Ruhe- EKG, Langzeit- Blutdruckmessung
                        <span className=" font-normal"></span>
                    </li>
                    <li className="leading-loose font-bold">Lungenfunktionstest
                    </li>
                    <li className="leading-loose font-bold">Kinder- und Jugendvorsorgeuntersuchungen und Jugendarbeitsschutzuntersuchungen
                    </li>
                    <li className="leading-loose font-bold">Injektionen, Infusionen, Punktionen
                    </li>
                    <li className="leading-loose font-bold">Kleinere Operationen, Wundversorgung
                    </li>
                    <li className="leading-loose font-bold">Tropenreise- und Impfberatung, Impfungen
                    </li>
                    <li className="leading-loose font-bold">
                        Disease Management Programme
                        <span className="font-normal"> (KHK, Diabetes Typ 2, Asthma und COPD, demnächst auch Adipositas und Osteoporose)</span>
                    </li>
                    <li className="leading-loose font-bold">Notfallmedizin
                    </li>
                    <li className="leading-loose font-bold">Psychosomatische Grundversorgung
                    </li>
                </ul>
            </div>
            <div className="py-10"></div>
            <div className="card bg-primary/50 shadow-2xl hover:shadow-2xl">
                <div className="card-body">
                    <div>
                        <h1 className="card-title">Hinweis</h1>
                        <p>
                            Blutentnahme und Entgegennahme von Urin-, Stuhl- sowie anderen Proben zur mikrobiologischen Untersuchung Montag bis Donnerstag von 8:00-9:30 Uhr.
                            <br></br>
                            <br/> <span className="underline decoration-pink-600">Termine zur Sprechstunde und fürs Labor nur nach telefonischer Vereinbarung!</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="py-8"></div>
        </div>
    );
}