import React from 'react';
import {Outlet} from "react-router-dom";
import NavBar from "./pages/NavBar";
import GlobalFooter from "./pages/GlobalFooter";




function App() {
  return (
      <>
        <div>
            <NavBar/>
            <Outlet/>
            <GlobalFooter/>
        </div>
      </>
  );
}

export default App;
