export default function Impressum() { // TODO: Fix Kontaktemailaddresse und Link zur hu-ry Webseite
    return (
        <>
            <div className="container mx-auto px-8 py-8 leading-loose">
                <div className="text-5xl"><h1>Impressum</h1></div>
                <div className="py-2"></div>
                <div className="leading-loose">
                    <p className="py-2">
                        <strong>
                        Dr. med. Thomas Bonin <br></br>
                        Facharzt für Allgemeinmedizin
                        </strong>
                    </p>
                    <p className="py-2">
                        Büchelbach 18
                        <br></br>
                        63599 Biebergemünd - Bieber
                    </p>
                    <p className="py-2">
                        Telefon: +49 6050 1516
                        <br></br>
                        Telefax: +49 6050 2051
                        <br></br>
                        E-Mail: <a className="text-accent hover:underline " href="mailto:praxis@dr-bonin.de">praxis@dr-bonin.de</a>
                    </p>
                    <p className="py-2">
                        LANR: 922189601 <br></br>
                        BSNR: 409004000 <br></br>
                    </p>
                    <p className="py-2">
                        <strong>
                            Verantwortlich für den Inhalt:
                        </strong>
                        <br></br>
                        Dr. med. Thomas Bonin
                    </p>
                    <p className="py-2">
                        <strong>
                            Berufsbezeichnung:
                        </strong>
                        <br></br>
                        Facharzt für Allgemeinmedizin (verliehen in Deutschland)
                    </p>
                    <p className="py-2">
                        <strong>
                            Zuständige Aufsichtsbehörde:
                        </strong>
                        <br></br>
                        Kassenärztliche Vereinigung Hessen
                        <br></br>
                        Europa-Allee 90
                        <br></br>
                        60486 Frankfurt/Main
                        <br></br>
                        <a className="text-accent" href="https://www.kvhessen.de">www.kvhessen.de</a>
                    </p>
                    <p className="py-2">
                        <strong>
                            Zuständige Kammer:
                        </strong>
                        <br></br>
                        Landesärztekammer Hessen
                        <br></br>
                        Hanauer Landstraße 152
                        <br></br>
                        60314 Frankfurt
                    </p>
                    <p className="py-2">
                        <strong>
                            Berufsrechtliche Regelungen:
                        </strong>
                        <br></br>
                        <ul className="list-disc list-inside">
                            <li> Berufsordnung der Bundesärztekammer </li>
                            <li> Berufsordnung der Landesärztekammer Hessen </li>
                            <li> Heilberufsgesetz des Landes Hessen </li>
                        </ul>
                    </p>
                    <div className="text-2xl">
                        Konzept, Webdesign und Umsetzung:
                    </div>
                    <p className="py-2 font-bold">
                        Ryan Huth,
                        <br></br>
                        <a className="text-accent-focus hover:text-fuchsia-600 font-mono
                         hover:font-sans hover:decoration-2 hover:bg-amber-100
                         animate-pulse"
                           href="https://hu-ry.me/"> hu-ry </a>
                    </p>
                </div>
            </div>
        </>
    )
}