import {Link} from "react-router-dom";

export default function NavBar() {

    function Click() {
        window.scrollTo(0, 0)
    }


    return (
        <header className="sticky xl:navbar top-0 z-50 bg-base-100/90 shadow-md">
            <nav className="xl:flex xl:justify-start xl:content-end xl:mx-auto max-xl:grid max-xl:grid-cols-3
            max-xl:grid-flow-cols-dense">

                <div className="xl:hidden inline px-2 py-2 col-end self-center max-xl:visible xl:invisible">
                    <div className="dropdown dropdown-hover">
                        <label tabIndex={0}
                               htmlFor="nav-drawer"
                               className="drawer-button btn btn-square btn-ghost text-accent-900 hover:bg-primary-focus/80 hover:text-base-200/100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                        </label>
                        <ul tabIndex={0}
                            className="dropdown-content menu lg:menu-normal bg-base-100 w-72 rounded-box shadow-md">
                            <li className="">
                                <Link to="/"
                                      className="font-semibold text-md px-4 py-4 text-accent-900 hover:bg-primary hover:text-base-200/100"
                                      onClick={Click} >Startseite</Link>
                            </li>
                            <li className="">
                                <Link to="/team"
                                      className="font-semibold text-md px-4 py-4 text-accent-900 hover:bg-primary hover:text-base-200/100"
                                      onClick={Click}>Team</Link>
                            </li>

                            <li className="">
                                <Link to="/leistungen"
                                      className="font-semibold text-md px-4 py-4 text-accent-900 hover:bg-primary hover:text-base-200/100"
                                      onClick={Click}>Leistungen</Link>
                            </li>
                            <li className="">
                                <Link to="/bewerbung"
                                      className="font-semibold text-md px-4 py-4 text-accent-900 hover:bg-primary hover:text-base-200/100"
                                      onClick={Click}>Stellenausschreibung</Link>
                            </li>
                            <li className="divider"></li>
                            <li className="">
                                <Link to="/ueberuns"
                                      className="font-semibold text-md px-4 py-4 text-accent-900 hover:bg-primary hover:text-base-200/100"
                                      onClick={Click}>Über Uns</Link>
                            </li>
                            <li className="">
                                <Link to="/sprechstunden"
                                      className="font-semibold text-md px-4 py-4 text-accent-900 hover:bg-primary hover:text-base-200/100"
                                      onClick={Click}>Sprechstunden</Link>
                            </li>
                            <li className="">
                                <Link to="/notfall"
                                      className="font-semibold text-md px-4 py-4 text-accent-900 hover:bg-primary hover:text-base-200/100"
                                      onClick={Click}>Bei Notfällen</Link>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="xl:hidden inline px-2 py-2 max-xl:visible xl:invisible"></div>


                <div className="xl:flex xl:flex-row max-xl:px-2 max-xl:py-2 max-xl:self-center">
                    <img className="xl:flex-none w-52 xl:w-80 " src="/Praxis_banner.svg" alt="Logo"/>
                </div>

                <div className="px-8 invisible xl:visible"></div>
                <Link to="/"
                   className="group hidden xl:inline font-bold text-xl px-5 py-6 text-accent-900 hover:bg-primary hover:text-base-200/100
                   invisible xl:visible" onClick={Click} >
                    <div className="group-hover:scale-110 transform transition ">
                        Startseite
                    </div>
                </Link>
                <Link to="/team"
                   className="group hidden xl:inline font-bold text-xl px-5 py-6 text-accent-900 hover:bg-primary hover:text-base-200/100
                   invisible xl:visible" onClick={Click}>
                    <div className="group-hover:scale-110 transform transition ">
                        Team
                    </div>
                </Link>
                <Link to="/leistungen"
                   className="group hidden xl:inline font-bold text-xl px-5 py-6 text-accent-900 hover:bg-primary hover:text-base-200/100
                   invisible xl:visible" onClick={Click}>
                    <div className="group-hover:scale-110 transform transition ">
                        Leistungen
                    </div>
                </Link>
                <Link to="/bewerbung"
                   className="group hidden xl:inline font-bold text-xl px-6 py-6 text-accent-900 hover:bg-primary hover:text-base-200/100
                   invisible xl:visible"
                      onClick={Click}>
                    <div className="group-hover:scale-110 transform transition ">
                        Stellenausschreibung
                    </div>
                </Link>
                <div className="hidden xl:inline invisible xl:visible"></div>
            </nav>

        </header>
    )
}

/*
<div className="drawer drawer-end">
  <input id="nav-drawer" type="checkbox" className="drawer-toggle" />
  <div className="drawer-content">
    <!-- Page content here -->
    <label htmlFor="nav-drawer" className="drawer-button btn btn-primary">Open drawer</label>
  </div>
  <div className="drawer-side">
    <label htmlFor="nav-drawer" className="drawer-overlay"></label>
    <ul className="menu p-4 w-80 bg-base-100 text-base-content">
      <!-- Sidebar content here -->
      <li><a>Sidebar Item 1</a></li>
      <li><a>Sidebar Item 2</a></li>
    </ul>
  </div>
</div>

<button className="btn btn-square btn-ghost ">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="inline-block w-5 h-5 stroke-current"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path></svg>
                    </button>
 */