import React from "react";

export default function AboutUs() {
    return(
        <div className="container mx-auto px-8 py-8 mb-8 xl:px-16">
            <div>
                <div className="hero min-h-screen bg-gray-100 py-6 rounded-lg">
                    <div className="hero-content flex-col lg:flex-row-reverse px-10 py-6">
                        <img src="/Thomas_Wide.jpg" className="max-md:hidden inline max-w-sm rounded-lg shadow-2xl" alt="praxisteam"/>
                        <img src="/Thomas_Wide_small.jpg" className="md:hidden max-w-xs rounded-lg shadow-2xl" alt="praxisteam" />
                        <div className="text-sm lg:text-base px-2 py-4 lg:mr-2">
                            <h1 className="text-xl xl:text-3xl font-bold">Über uns</h1>
                            <p className="py-4 text-justify text-base">
                                Als Facharzt für Allgemeinmedizin betreibe ich eine leistungsstarke,
                                vielseitige Landarzt-Praxis. Unsere Stärke ist die Gesundheitsversorgung
                                über alle Generationen hinweg, im besten Sinne einer family
                                medicine, auf Ihre persönlichen Bedürfnisse eingehend.
                                Vor meiner Niederlassung war ich in Orthopädie, Neurologie, Chirurgie,
                                Notfall- und Innerer Medizin tätig, sodann ab 1997 in Tropenmedizin und
                                Public Health (Peru, Kongo, Malediven, Philippinen).
                                2000 übernahm ich dann die hiesige Praxis. Unser MFA-Team ist auf hohem Niveau
                                für Sie tätig, in Prävention, Diagnostik und Therapie unterstützt es mich umfassend.
                                Wir helfen Ihnen auf deutsch, englisch, spanisch, französisch, italienisch, tagalog und ilongo.
                                Ich bin verheiratet und habe drei Kinder. Meine Hobbies sind Gärtnern, Malen, Radeln, Fremdsprachen
                                lernen. Und ich bin sehr gern als Arzt für Sie da, im vollen Spektrum der Gesundheitsfürsorge.
                                <br></br><br></br>
                                Ihr Dr. med. Thomas Bonin
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}